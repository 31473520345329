import {submitMerge, submitMiniAction, submitMove, trackOnNextPageImpression} from "@otto-ec/tracking-bct";

export default class TrackingService {

    sendClickTrackingEvent(featureOrder, featureIndex, filledSlots, position, variation) {
        const labelTrackingData = this._createLabelClickTracking(featureOrder, featureIndex, filledSlots, position, variation.isOnWishlist);

        let featureTrackingData = {
            name: "click",
            features: [this._createArticleListFeatureTrackingLabel(featureIndex, featureOrder, filledSlots, "clicked"),
                this._createArticleListFeatureTrackingLabelArticle(featureIndex, position, "clicked", variation.variationId, variation.articleName, variation.isOnWishlist)
            ]
        }
        submitMove(labelTrackingData, featureTrackingData)
        trackOnNextPageImpression({"promo_Click": ["ArticleList"]})
    }

    sendViewTrackingEvent(featureOrder, featureIndex, filledSlots, variations) {
        let trackingData = [this._createArticleListFeatureTrackingLabel(featureIndex, featureOrder, variations.length, "loaded")];
        [].map.call(variations, (variation, index) => {
            trackingData.push(this._createArticleListFeatureTrackingLabelArticle(featureIndex, index + 1, "loaded", variation.variationId, variation.articleName, variation.isOnWishlist))
        });
        submitMerge({}, trackingData)
    }

    sendScrollTrackingEvent(featureIndex, visiblePositions) {
        const parentFeature = {featureId: this._getArticleListFeatureId(featureIndex)}
        const childFeatures = visiblePositions.map((position) => {
            return {featureId: this._getArticleListArticleFeatureId(featureIndex, position)}
        })
        if (childFeatures.length > 0) {
            submitMiniAction([parentFeature, ...childFeatures])
        }
    }

    _createLabelClickTracking(featureOrder, featureIndex, filledSlots, position, isOnWishlist) {
        const clickTrackingInfo = {};

        if (isOnWishlist !== undefined) {
            clickTrackingInfo["order_ProductOnWishlist"] = [isOnWishlist];
        }
        let pageCluster = window.o_util.misc.getPagecluster();
        clickTrackingInfo["wk.promo_AttributionFeature"] = [`ArticleList_${pageCluster}`]
        clickTrackingInfo["wk.promo_AttributionSource"] = ["ArticleList"]
        return clickTrackingInfo;
    }

    _createArticleListFeatureTrackingLabel(featureIndex, featureOrder, filledSlots, status) {
        return {
            id: this._getArticleListFeatureId(featureIndex),
            name: "ArticleList",
            position: featureOrder,
            status: status,
            labels: {
                promo_FeatureNumber: [(featureIndex + 1).toString()],
                promo_FilledSlots: [filledSlots.toString()],
                promo_Feature: ["true"]
            }
        }
    }

    _createArticleListFeatureTrackingLabelArticle(featureIndex, position, status, variationId, articleName, isOnWishlist) {
        let labels = {
            promo_Content: [articleName],
            promo_Source: ["n/a"]
        }
        if (isOnWishlist !== undefined) {
            labels.order_ProductOnWishlist = [isOnWishlist.toString()];
        }

        return {
            id: this._getArticleListArticleFeatureId(featureIndex, position),
            parentId: "ft3_al" + featureIndex,
            name: "ArticleList-Article",
            position: position,
            status: status,
            variationId: variationId,
            labels: labels
        };
    }

    _getArticleListFeatureId(featureIndex) {
        return 'ft3_al' + featureIndex;
    }

    _getArticleListArticleFeatureId(featureIndex, position) {
        return this._getArticleListFeatureId(featureIndex) + "_article" + position;
    }
}
